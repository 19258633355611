import React from "react";
import ReactDOM from "react-dom";
import "../Style.css"
import John from "../hooks/johnthumb";

const Contact = () => {
	return (
		<div>
			<div className="Notagainjohn">
				<div className="containereejohn">
				<div className="Johnspecial">
				<John />
				</div>
				</div>
				</div>
		</div>
	);
};

export default Contact;
